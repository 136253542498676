<div class="wrapper">
    <div class="sidebar" [ngStyle]="{'transform' : innerWidth >= 991 ? sidebarStatus : null}" data-color="white" data-background-color="white">
      <div class="sidebar-backdrop" [ngClass]="{'block': innerWidth >= 991 && sidebarStatus == 'translateX(-235px)'}"></div>
      <div class="left-arrow"  (click)="toggleSidebar()"
      [ngClass]="{'rotate-180': innerWidth >= 991 && sidebarStatus == 'translateX(-235px)', 'flex': innerWidth >= 991 }">
        <!-- <app-icon [icon]="'leftDoubleArrow'" [dimensions]="'25px'"></app-icon> -->
        <i class="fa fa-angle-double-left"></i>
      </div>
      <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel" [ngClass]="{'main-100': innerWidth >= 991 && sidebarStatus === 'translateX(-235px)'}">
      <app-navbar></app-navbar>
      <div class="main-content my-4">
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  