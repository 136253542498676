<section>
    <div class="card">
        <div class="card-header card-box-shadow bt-4 d-flex justify-content-between p-3">
            <h4 class="card-title m-0 float-left font-weight-bold">
                Terms And Conditions
            </h4>
            <div class="d-flex justify-content-end">
                <div>
                    <mat-icon matTooltip="Add a Press Coverage" (click)="openSubscriptionModal()"
                        class="pr-3 card-header-icon pointer" matTooltipClass="custom-tooltip">
                        add
                    </mat-icon>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="w-100 table-responsive">
                <table class="w-100" mat-table [dataSource]="dataSource">

                    <ng-container matColumnDef="group_name">
                        <th mat-header-cell *matHeaderCellDef> Group Name </th>
                        <td mat-cell *matCellDef="let element">
                            <div>
                                {{element?.group_name}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tab_name">
                        <th mat-header-cell *matHeaderCellDef>Tab Name </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="my-3">
                                {{element?.tab_name || 'N/A'}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="heading">
                        <th mat-header-cell *matHeaderCellDef>Heading </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="my-3">
                                {{element?.heading || 'N/A'}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="sub_heading">
                        <th mat-header-cell *matHeaderCellDef>Sub Heading </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="my-3">
                                {{element?.sub_heading || 'N/A'}}
                            </div>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="desktop_image">
                        <th mat-header-cell *matHeaderCellDef>Desktop Image</th>
                        <td mat-cell *matCellDef="let element">
                            <img [src]="element.desktop_image_full_url" width="100" height="100" class="my-3">
                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let element">
                            <a class="pointer" matTooltip="Edit Press Coverage"
                                (click)="openSubscriptionModal(element,true)" matTooltipClass="custom-tooltip">
                                <i style="font-size: 1.6em; color: #00b3ac;" class="fas fa-2x fa-pen-square"></i>
                            </a>
                            <a class="pointer ml-3" (click)="deleteItem(element)" [matTooltip]="'Delete File'"
                                matTooltipClass="custom-tooltip">
                                <i style="font-size: 1.6em; color: #c41414;" class="fas fa-2x fa-trash-alt"></i>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div class="text-center py-2 no-record" *ngIf="!dataSource?.length">
                No record found</div>
            <mat-paginator [length]="totalPages" [pageSizeOptions]="[50, 100, 200]" (page)="pageChanged($event)" [pageSize]="pageSize">
            </mat-paginator>
        </div>
    </div>
</section>