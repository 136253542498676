<section>
    <div class="card">
        <div class="card-header card-box-shadow bt-4 d-flex justify-content-between p-3">
            <h4 class="card-title m-0 float-left font-weight-bold">
                Warranty Information
            </h4>
            <div class="d-flex justify-content-end">
                <div>
                    <mat-icon matTooltip="Add a Press Coverage" (click)="openSubscriptionModal()"
                        class="pr-3 card-header-icon pointer" matTooltipClass="custom-tooltip">
                        add
                    </mat-icon>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="w-100 table-responsive">
                <table class="w-100" mat-table [dataSource]="dataSource">

                    <ng-container matColumnDef="start_date">
                        <th mat-header-cell *matHeaderCellDef>Start Date</th>
                        <td mat-cell *matCellDef="let element">
                            <div>
                                {{element?.warranty_duration_start_date}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="end_date">
                        <th mat-header-cell *matHeaderCellDef>End Date</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="my-3">
                                {{element?.warranty_duration_end_date || 'N/A'}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="purchase_mode">
                        <th mat-header-cell *matHeaderCellDef>Purchase Mode</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="my-3">
                                {{element?.purchase_mode || 'N/A'}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="warranty_description_text">
                        <th mat-header-cell *matHeaderCellDef>Warranty Description</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="my-3">
                                {{element?.warranty_description_text || 'N/A'}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="product_varients">
                        <th mat-header-cell *matHeaderCellDef>Product Variants</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="my-3">
                                {{element?.product_varients || 'N/A'}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let element">
                            <a class="pointer" matTooltip="Edit"
                                (click)="openSubscriptionModal(element,true)" matTooltipClass="custom-tooltip">
                                <i style="font-size: 1.6em; color: #00b3ac;" class="fas fa-2x fa-pen-square"></i>
                            </a>
                            <a class="pointer ml-3" (click)="deleteItem(element)" [matTooltip]="'Delete'"
                                matTooltipClass="custom-tooltip">
                                <i style="font-size: 1.6em; color: #c41414;" class="fas fa-2x fa-trash-alt"></i>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div class="text-center py-2 no-record" *ngIf="!dataSource?.length">
                No record found</div>
            <mat-paginator [length]="totalPages" [pageSizeOptions]="[50, 100, 200]" (page)="pageChanged($event)" [pageSize]="pageSize">
            </mat-paginator>
        </div>
    </div>
</section>