<section class="pb-1">
  <div class="card">
    <div class="card-header card-box-shadow bt-4 d-flex align-items-center">
      <h4 class="card-title m-0 font-weight-bold">Categories</h4>
      <a style="margin-left: 8px;" target="_blank" *ngIf="relativeUrl"
        [href]="environment.websiteUrl + relativeUrl">(View on Website)</a>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12 mt-3">
          <mat-tab-group animationDuration="0ms" #ctdTabset (selectedTabChange)="changeTab($event)"
            [selectedIndex]="selectedIndex">
            <mat-tab [label]="'Details'">

              <div class="row p-3">
                <div class="col-md-12">
                  <form [formGroup]="productVarientForm">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <mat-form-field appearance="outline">
                          <mat-label class="mat-label-field-padding">Category Code</mat-label>
                          <input matInput formControlName="code" type="text" />
                          <mat-error
                            *ngIf="productVarientFormSubmitted || (formControl['code'].errors && formControl['code'].errors['required'])">
                            Category Code is required!
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <mat-form-field appearance="outline">
                          <mat-label class="mat-label-field-padding">Category Name</mat-label>
                          <input matInput formControlName="name" type="text" />
                          <mat-error
                            *ngIf="productVarientFormSubmitted || (formControl['name'].errors && formControl['name'].errors['required'])">
                            Category Name is required!
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                          <mat-form-field appearance="outline">
                            <mat-label class="mat-label-field-padding">Product Size</mat-label>
                            <input matInput formControlName="variant_size" type="text" />
                            <mat-error
                              *ngIf="productVarientFormSubmitted || (formControl['variant_size'].errors && formControl['variant_size'].errors['required'])">
                              Product Size is required!
                            </mat-error>
                          </mat-form-field>
                        </div> -->
                      <div class="col-12 col-sm-6 col-md-6 col-lg-3 form-group">
                        <mat-form-field appearance="outline">
                          <mat-label>Parent Category</mat-label>
                          <mat-select formControlName="parent_category_id">
                            <mat-option *ngFor="let product of products" [value]="product.id">
                              {{ product.name }}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="productVarientFormSubmitted || (formControl['parent_category_id'].errors && formControl['parent_category_id'].errors['required'])">
                            Product Category is required!
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <!-- <div class="col-12 form-group">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Short Description</mat-label>
                            <textarea matInput trim="blur" style="resize: none; overflow-y: auto; padding: 8px;" rows="5"
                              formControlName="short_description"></textarea>
                            <mat-error
                              *ngIf="productVarientFormSubmitted || (formControl['short_description'].errors && formControl['short_description'].errors['required'])">
                              Product Group is required!
                            </mat-error>
                          </mat-form-field>
                        </div> -->
                      <div class="col-12 form-group" *ngIf="componentType == 'update'">
                        <mat-slide-toggle formControlName="is_enabled" class="d-block example-margin">Enable/Disable the
                          category</mat-slide-toggle>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </mat-tab>

            <mat-tab [label]="'Product Groups'" *ngIf="componentType == 'update'">

              <div class="w-100 table-responsive">
                <table mat-table [dataSource]="dataSource">

                  <ng-container matColumnDef="Product Group">
                    <th mat-header-cell *matHeaderCellDef> Product Group </th>
                    <td mat-cell *matCellDef="let element"> {{element.product.name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="Open on Website">
                    <th mat-header-cell *matHeaderCellDef> Open on Website </th>
                    <td mat-cell *matCellDef="let element; let i = index"> <a
                        [href]="environment.websiteUrl + element.product.relative_url"
                        target="_blank">{{environment.websiteUrl}}{{element.product.relative_url}}</a> </td>
                  </ng-container>

                  <ng-container matColumnDef="priority">
                    <th mat-header-cell *matHeaderCellDef>Priority </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field style="max-width: 80px;" appearance="outline">
                        <mat-label>Priority</mat-label>
                        <mat-select [(ngModel)]="element.priority">
                          <mat-option *ngFor="let priority of priorities" [value]="priority">
                            {{ priority }}
                          </mat-option>
                        </mat-select>
                        <mat-hint class="error" *ngIf="!element.priority">
                          Proority is required!
                        </mat-hint>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <i [routerLink]="['/update/product-group',element.product.id]"
                        style="font-size: 1.6em; color: #00b3ac;" class="fas fa-2x fa-pen-square pointer"></i>
                    </td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <div class="text-center my-3" *ngIf="!dataSource?.length">No Records Found!</div>

              </div>

            </mat-tab>
            <mat-tab [label]="'Images'" *ngIf="componentType == 'update'">

              <div class="m-3">
                <mat-tab-group animationDuration="0ms" [selectedIndex]="imagesTabSelectedIndex">
                  <mat-tab [label]="'Thumb Image'">
                    <div class="w-100 m-3 table-responsive">
                      <table mat-table [dataSource]="imagesDataSource">

                        <ng-container matColumnDef="Image Type">
                          <th mat-header-cell *matHeaderCellDef> Image Type </th>
                          <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Current Image">
                          <th mat-header-cell *matHeaderCellDef> Current Image </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <img class="my-2" [src]="element.full_url" width="100" height="100" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="Upload New Image">
                          <th mat-header-cell *matHeaderCellDef> Upload New Image </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <a href="javascript:void(0);" (click)="browseFileButtonClicked(i)">Browse Image</a>
                            <input #input (change)="inputChangeEventListener($event,element.type,i)" type="file" hidden
                              accept="image/png, image/jpeg, image/webp" />
                          </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="imagesDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: imagesDisplayedColumns;"></tr>
                      </table>
                      <div class="text-center my-3" *ngIf="!imagesDataSource?.length">No Records Found!</div>


                      <!-- [ngClass]="{'not-allowed': !form.valid}" -->
                      <!-- [disabled]="!form.valid" -->
                      <div class="pt-1 m-3">
                        <button class="btn-info" mat-raised-button mat-button color="primary"
                          [ngClass]="{'spiner':isLoader}" type="submit" style="font-size: 16px;"
                          (click)="uploadImages()">Upload
                          <i *ngIf="isLoader" class="fas fa-circle-notch fa-spin "></i>
                        </button>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="col-md-12 mt-4 border-top p-2 button-container d-flex justify-content-between align-items-center">
        <div>
          <a (click)="goBack()" class="forgot-link" style="color: black; cursor: pointer;">
            <i class='fas fa-arrow-left'></i> Go back
          </a>
        </div>
        <!-- [disabled]="!productVarientForm.valid || isLoading" -->
        <button *ngIf="selectedIndex != 2" mat-raised-button type="submit" (click)="saveProductDetailsForm()"
          class="btn btn-info" style="display: flex; gap: 12px;">
          <span [ngClass]="{'mr-2': isLoading}">
            <ng-container *ngIf="componentType == 'create'">Create</ng-container>
            <ng-container *ngIf="componentType == 'update'">Update</ng-container>
          </span>
          <i *ngIf="isLoading" class="fas fa-circle-notch fa-spin"></i>
        </button>
      </div>
    </div>
  </div>
</section>