<section class="cards-container">
    <article class="row">
        <article class="col-md-4 col-sm-6 col-xs-12 ">
            <!--fa fa-location-arrow-->
            <app-stats-card class="cursor-pointer" [routerLink]="['/list/category']"
                [headerType]="'card-header-warning'" [cardIcon]="'fas fa-users-cog'" [heading]="'Categories'"
                >
                <!-- [value]="dashboardInformation?.accounts_count" -->
            </app-stats-card>
        </article>
        
        <article class="col-md-4 col-sm-6 col-xs-12 ">
            <app-stats-card class=" cursor-pointer" [routerLink]="['/list/product-group']"
                [headerType]="'card-header-success'" [cardIcon]="'fa fa-user-tie'" [heading]="'Product groups'"
                ></app-stats-card>
                <!-- [value]="dashboardInformation?.company_customers_count" -->
        </article>

        <article class="col-md-4 col-sm-6 col-xs-12  cursor-pointer">
            <app-stats-card class=" cursor-pointer" [routerLink]="['/list/product-varients']" [headerType]="'card-header-rose'"
                [cardIcon]="'fas fa-bullhorn'" [heading]="'Products'"
                ></app-stats-card>
                <!-- [value]="dashboardInformation?.thaut_campaigns_count"  -->
        </article>
        <article class="col-md-4 col-sm-6 col-xs-12  cursor-pointer">
            <app-stats-card class=" cursor-pointer" [routerLink]="['/contact-requests']" [headerType]="'card-header-contact-req'"
                [cardIcon]="'fa fa-plus'" [heading]="'Contact Requests'"
                ></app-stats-card>
                <!-- [value]="dashboardInformation?.thaut_campaigns_count"  -->
        </article>
        <article class="col-md-4 col-sm-6 col-xs-12  cursor-pointer">
            <app-stats-card class=" cursor-pointer" [routerLink]="['/press-coverages']" [headerType]="'card-header-press'"
                [cardIcon]="'far fa-chart-bar'" [heading]="'Press coverages'"
                ></app-stats-card>
                <!-- [value]="dashboardInformation?.thaut_campaigns_count"  -->
        </article>
        <article class="col-md-4 col-sm-6 col-xs-12  cursor-pointer">
            <app-stats-card class=" cursor-pointer" [routerLink]="['/tech-testimonials']" [headerType]="'card-header-drive'"
                [cardIcon]="'fab fa-google-drive'" [heading]="'Tech testimonials'"
                ></app-stats-card>
                <!-- [value]="dashboardInformation?.thaut_campaigns_count"  -->
        </article>
        <article class="col-md-4 col-sm-6 col-xs-12  cursor-pointer">
            <app-stats-card class=" cursor-pointer" [routerLink]="['/offers-and-promotions']" [headerType]="'card-header-offers'"
                [cardIcon]="'fa fa-credit-card'" [heading]="'Offers and promotions'"
                ></app-stats-card>
                <!-- [value]="dashboardInformation?.thaut_campaigns_count"  -->
        </article>

        <article class="col-md-4 col-sm-6 col-xs-12  cursor-pointer">
            <app-stats-card class="cursor-pointer" [routerLink]="['/home-slider']" [headerType]="'card-header-invoice'"
                [cardIcon]="'fas fa-file-invoice'" [heading]="'Home Page Slides'"
                ></app-stats-card>
                <!-- [value]="dashboardInformation?.thaut_campaigns_count"  -->
        </article>

    </article>
</section>