<section class="vh-100">
    <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col col-xl-10">
                <div class="card box" style="border-radius: 1rem;">
                    <div class="row ">
                        <div class="col-md-6 col-lg-5 logo-container "
                            style="display: flex; align-items: center;justify-content: center; padding: 50px;">
                            <img src="assets/img/logo.svg" alt="logo" class="img-fluid logo-img" width="200"/>
                        </div>
                        <div class="col-md-6 col-lg-7 d-flex align-items-center">
                            <div class="card-body p-4 p-lg-5 text-black">
                                <form [formGroup]="form" (ngSubmit)="onLogin()">
                                    <h5 class="fw-bold mb-3 pb-3 text-secondary"
                                        style="letter-spacing: 1px; font-size: 24px;white-space: nowrap;">Log in to your
                                        account
                                    </h5>
                                    <div class="form-outline mb-4">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-icon matSuffix class="text-muted">mail</mat-icon>
                                            <mat-label>Email address</mat-label>
                                            <input matInput formControlName="email" type="email">
                                            <mat-error *ngIf="form.controls['email'].errors && form.controls['email'].errors['required']">Email field is required!</mat-error>
                                            <mat-error *ngIf="form.controls['email'].errors && form.controls['email'].errors['email']">Enter a valid Email address!</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-outline mb-4">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Password</mat-label>
                                            <input matInput [type]="hide ? 'password' : 'text'"
                                                formControlName="password">
                                            <mat-icon matSuffix class="text-muted cursor-pointer" (click)="hide = !hide">{{hide ? 'visibility_off' :
                                                'visibility'}}</mat-icon>
                                                <mat-error *ngIf="form.controls['password'].errors && form.controls['password'].errors['required']">Password field is required!</mat-error>
                                                <mat-error *ngIf="form.controls['password'].errors && form.controls['password'].errors['minlength']">Password field should contain atleast 8 letters</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="pt-1 mb-4" style="display: inline;" [ngClass]="{'not-allowed': !form.valid}">
                                        <button class="w-100 btn-info" mat-raised-button mat-button color="primary"
                                            [ngClass]="{'spiner':isLoader}" type="submit" [disabled]="!form.valid"
                                            style="font-size: 16px;">Login
                                            <i *ngIf="isLoader" class="fas fa-circle-notch fa-spin "></i>
                                        </button>
                                    </div>
                                    <!-- <a class="text-muted" [routerLink]="['/forgot-password']"
                                        style="font-size: 16px; font-weight: 400;">Forgot
                                        password?
                                    </a> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>