<section class="nav-wrapper">
  <nav class="navbar navbar-expand-lg bg-white d-md-none navbar-absolute bg-primary fixed-top">
    <div class="container-fluid">
      <!-- <div class="navbar-wrapper">
        <a class="navbar-brand"  routerLink='/dashboard'>
          <img class="img-fluid" src="./assets/img/logo.png" />
        </a>
      </div> -->
      <div class="navbar-toggle py-2">
        <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
          <span class="sr-only">Toggle navigation</span>
          <span class="navbar-toggler-icon icon-bar"></span>
          <span class="navbar-toggler-icon icon-bar"></span>
          <span class="navbar-toggler-icon icon-bar"></span>
        </button>
      </div>
    </div>
  </nav>
</section>
