<section class="pb-1">
  <div class="card">
    <div class="card-header card-box-shadow bt-4">
      <h4 class="card-title m-0 font-weight-bold">Product Variants List</h4>
    </div>
    <div class="card-body">

      <div class="w-100 table-responsive">
        <table mat-table [dataSource]="dataSource">

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name. </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- code Column -->
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef> Code </th>
            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
          </ng-container>

          <!-- Product Code Column -->
          <ng-container matColumnDef="Product Link">
            <th mat-header-cell *matHeaderCellDef> Product Link </th>
            <td mat-cell *matCellDef="let element">
              <a [href]="environment.websiteUrl + element.relative_url" target="_blank">{{environment.websiteUrl}}{{element.relative_url}}</a>
            </td>
          </ng-container>

          <!-- Size Column -->
          <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef> Variant Size </th>
            <td mat-cell *matCellDef="let element"> {{element.variant_size}} </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
              <a matTooltip="Edit Product" matTooltipClass="custom-tooltip"
                [routerLink]="['/update/product-varients',element.id]">
                <i style="font-size: 1.6em; color: #00b3ac;" class="fas fa-2x fa-pen-square"></i>
              </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="totalPages" [pageSizeOptions]="[50, 100, 200]" (page)="pageChanged($event)" [pageSize]="pageSize">
        </mat-paginator>
      </div>


      <div class="col-md-12 border-top p-2 button-container d-flex justify-content-between align-items-center">
        <div>
          <a (click)="goBack()" class="forgot-link" style="color: black; cursor: pointer;">
            <i class='fas fa-arrow-left'></i> Go back
          </a>
        </div>
      </div>
    </div>
  </div>
</section>